import React, { useState, useEffect } from "react";
import { MinApp } from "../components/MinApp";
import { PageContext }from '../contexts/pageContext'
import Head from 'next/head';
import Script from 'next/script';
import { getConfig } from '../contexts/configs/index'


function FeedPage() {

const shopDomain = process.env.NEXT_PUBLIC_API_URL;

return (
    <>
    <Head>
      <title>Claros - Supplements</title>
      <link rel="icon" href="/logo.png" />
      <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
      <meta name="description" content="Find the best supplements tailored for your health needs, curated by AI." />
      <meta property="og:title" content="Claros - Supplements" />
      <meta property="og:description" content="Find the best supplements tailored for your health needs, curated by AI." />
      <meta property="og:url" content="https://www.claros.so/supplements" />
      <meta property="og:type" content="website" />
      <meta property="twitter:title" content="Claros - Supplements" />
      <meta property="twitter:description" content="Find the best supplements tailored for your health needs, curated by AI." />
    </Head>

    <PageContext.Provider value={getConfig("supplements")}>
            {<MinApp/> }
    </PageContext.Provider>
    </>
  )
}
export default FeedPage




